var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual', {
          attrs: {
            "sh": "Lawfirm taesung & P.E",
            "tabActive": "윤영환 대표 소개",
            "bg": "/images/sub/visual/sv-introduction.jpg"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('page-section', {
    staticClass: "lawyer-view__head",
    attrs: {
      "tit": "윤영환 대표 소개",
      "titAdd": "Introduction",
      "titClass": "pb-0"
    },
    scopedSlots: _vm._u([{
      key: "pageSectionImmersive",
      fn: function () {
        return [_c('v-img', {
          staticClass: "text-bg w-100",
          attrs: {
            "src": "/images/sub/introduction/lawyer/lawyer-bg.svg",
            "max-width": "1840",
            "aspect-ratio": 1840 / 266
          }
        })];
      },
      proxy: true
    }])
  }), _c('page-section', {
    staticClass: "lawyer-view__body grey darken-3"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "7"
    }
  }, [_c('tit', {
    staticClass: "tit--lg white--text",
    attrs: {
      "data-aos": "fade-up"
    }
  }, [_vm._v(" 어떠한 난제에 봉착하더라도 "), _c('br'), _c('span', {
    staticClass: "primary--text"
  }, [_vm._v("심도 있는 실무적 관점으로 다가가겠습니다.")])]), _c('span', {
    staticClass: "line my-12px my-md-24px",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "100"
    }
  }), _c('txt', {
    staticClass: "white--text line-height-17",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "200"
    }
  }, [_c('p', [_vm._v(" 윤영환 변호사는 건설 전공자 출신으로 건축시공기술사와 토목기사를 취득하여 활동하고 있는 실무가 출신의 대표변호사로서, 각 분야에 심도 있는 이해를 추구하고 의뢰인과 가깝게 호흡하는 현장 밀착형 로펌을 추구합니다. ")]), _c('p', [_vm._v(" 기존에 한계라 여겼던 부분들을 실무적 관점으로 풀어가는 변호사인 만큼, 풀리지 않는 난제에 봉착하였다면, "), _c('br', {
    staticClass: "d-none d-xl-block"
  }), _vm._v(" 언제든지 윤영환 대표변호사를 찾아 주시기 바랍니다. ")])])], 1), _c('v-col', {
    attrs: {
      "cols": "8",
      "sm": "5",
      "offset": "4",
      "offset-sm": "7",
      "offset-md": "0"
    }
  }, [_c('div', {
    staticClass: "lawyer-img-wrap"
  }, [_c('v-img', {
    staticClass: "lawyer-img w-100",
    attrs: {
      "data-aos": "fade-left",
      "src": "/images/lawyer/view/윤영환.png",
      "max-width": "586",
      "aspect-ratio": 586 / 900
    }
  })], 1)])], 1)], 1), _c('page-section', {
    staticClass: "lawyer-view__foot page-section--last page-section--xs"
  }, [_c('info-row', {
    attrs: {
      "title": "소개글",
      "topLine": ""
    }
  }, [_c('txt', {
    staticClass: "txt--dark line-height-17"
  }, [_c('p', [_vm._v(" 건축물 하자관리 분야에서는, 스프링클러 전면교체, 방화문 전면교체 판결 등은 물론 찾아보기 어려운 전선 전면교체, 승강기 전면교체 등의 우수한 성과를 이뤄 오고 있습니다. ")]), _c('p', [_vm._v(" 또한 입주예정자협의회 지원 분야에서는, 기존의 명목뿐이었던 로펌의 역할을 파트너 지위로 끌어올렸다는 평가를 얻고 있으며 정비사업 분야에서는 시공사와 대등한 입장에서의 계약조율, 확실한 정산 소송 등의 성과를 얻어오고 있습니다. ")]), _c('p', [_vm._v(" 건설 실무가 출신의 변호사답게, 변호사, 기술자, 스태프들의 유기적인 소통과 조화를 이끌어 내어 단체소송, 집단소송에서 압도적인 성과를 자랑하고 있습니다. ")])])], 1), _vm._l(_vm.info, function (item, index) {
    return _c('info-row', {
      key: index,
      attrs: {
        "title": item.title
      }
    }, _vm._l(item.infoDetail, function (detail, index) {
      return _c('info-detail', {
        key: index,
        class: index !== 0 ? 'mt-10px mt-md-16px' : ''
      }, [_vm._v(" " + _vm._s(detail) + " ")]);
    }), 1);
  })], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }