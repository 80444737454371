var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual', {
          attrs: {
            "sh": "오시는 길",
            "tabActive": "광주분사무소",
            "bg": "/images/sub/visual/sv-contact.jpg"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('contact-info', {
    attrs: {
      "title": "광주분사무소",
      "iframe": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3262.2661579744517!2d126.93071957628064!3d35.14998235897328!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x35718cbde2cd95a7%3A0x1d98c0ba364f6baf!2z6rSR7KO86rSR7Jet7IucIOuPmeq1rCDsp4DsgrDroZwgNzA!5e0!3m2!1sko!2skr!4v1692692301259!5m2!1sko!2skr",
      "address": "광주 동구 지산로 70, 동산빌딩 2층",
      "addressLink": "https://goo.gl/maps/UoYnT7Ne8TuZmkVR8",
      "tel": "062-229-1727",
      "fax": "062-229-1728"
    }
  }), _c('contact-slide', [_c('v-img', {
    staticClass: "w-100",
    attrs: {
      "src": "/images/sub/contact/gwangju/gwangju-img.jpg",
      "max-width": "952",
      "aspect-ratio": 952 / 634
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }