<template>
    <client-page>
        <template #subHead>
            <sub-visual sh="업무분야" tabActive="정비사업" bg="/images/sub/visual/sv-business.jpg" />
        </template>
        <page-section tit="정비사업 법률자문" titAdd="Business" class="text-bg" style="background-image: url(/images/sub/business/business-bg.svg);">
            <div class="card-rows">
                <card-row image="/images/sub/business/maintenance/maintenance-img.jpg">
                    <p>
                        재개발·재건축 정비사업은 조합설립 단계에서부터 준공 후 이전고시
                        청산까지 긴 기간이 소요되고, 정비사업조합은 사업주체로서 시공사
                        선정부터 시공계획 수립 단계를 거쳐 아파트를 시공하고 완공하기까지 모든 과정에서 의사결정을 하고 현장을 관리해야 하기 때문에, 법률적인 면이나 건설기술 분야의 자문이 필수적입니다.
                    </p>
                </card-row>
                <card-row image="/images/sub/business/maintenance/maintenance-img2.jpg">
                    <p>
                        법무법인 태성은 건축 분야 최고 수준 자격증인 건축시공기술사와
                        건축기사, 토목기사 자격을 보유한 윤영환 변호사를 주축으로 다수의
                        건설전문변호사를 보유하고 있고, 사내 전문기술인력을 보유하고 있어 재개발 재건축 정비사업조합의 시작부터 종료까지 법률분야와
                        건설기술분야 전반에 대한 전문적인 조언과 컨설팅이 가능합니다.
                    </p>
                </card-row>
                <card-row image="/images/sub/business/maintenance/maintenance-img3.jpg">
                    <p>
                        법무법인 태성은 하자소송 분야와 신축 아파트 단체등기,
                        입주예정자협의회 법률자문 분야에서 업계 내 선도적인 역할을 하고
                        있고, 정비사업조합 업무까지 아파트와 관련된 주요 분야를 모두 수행할 수 있는 역량과 노하우를 갖추고 있습니다.
                    </p>
                    <p>
                        재개발·재건축조합, 입주자대표회의, 입주예정자협의회 등 아파트
                        입주민의 든든한 지원자이자 최고의 파트너로서 자리매김하고자 최선을 다하겠습니다.
                    </p>
                </card-row>
            </div>
        </page-section>
        <page-section tit="주요 업무" titAdd="Business" dark style="background-image: url(/images/sub/business/maintenance/maintenance-bg.jpg);">
            <info-row v-for="(item, index) in info" :key="index" :title="item.title" :topLine="index == 0" dark>
                <info-detail v-for="(detail, index) in item.infoDetail" :key="index" :class="index !== 0 ? 'mt-10px mt-md-16px' : ''">
                    {{detail}}
                </info-detail>
            </info-row>
        </page-section>
        <page-section tit="주요 업무사례" titAdd="Business" titLeft class="text-bg--2 pb-0"  style="background-image: url(/images/sub/business/business-bg2.svg);">
        </page-section>
        <page-section class="page-section--last page-section--xs grey lighten-5">
            <v-row>
                <v-col v-for="(detail, index) in precedent" :key="index" cols="12" md="6">                    
                    <card-primary data-aos="fade-up" :data-aos-delay="$vuetify.breakpoint.mdAndUp ? index*50 : 0 ">
                        <txt class="txt--dark">{{detail}}</txt>
                    </card-primary>
                </v-col>
            </v-row>
        </page-section>
    </client-page>
</template>

<script>

import ClientPage from "@/sets/styles/apps/client-page.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import PageSection from "@/sets/styles/pages/page-section.vue";
import CardRow from "@/sets/styles/card-row.vue";
import InfoRow from "@/sets/styles/info-row.vue";
import InfoDetail from "@/sets/styles/info-detail.vue";
import CardPrimary from "@/components/publish/parents/cards/card-primary.vue";

import Tit from "@/components/publish/styles/typography/tit.vue";
import Txt from "@/components/publish/styles/typography/txt.vue";

export default {
    props: {},
    components: {
        ClientPage,
        SubVisual,
        PageSection,
        CardRow,
        InfoRow,
        InfoDetail,
        CardPrimary,

        Tit,
        Txt,
    },
    data() {
        return {           
            info: [
                {
                    title : "정비사업 법률자문 관련",
                    infoDetail : [
                        "조합(추진위) 자문, 주요 회의 배석 현장 자문",
                        "매도청구, 신탁등기, 명도소송",
                        "수용재결 및 청산 관련 소유권이전등기 소송",
                        "관리처분계획 무효 취소 등 소송",
                        "조합설립 인가 처분 무효 확인 취소소송",
                        "행정소송 관련 집행정지 신청",
                        "총회결의 무효 취소소송, 총회개최금지 가처분",
                        "조합 정산 소송, 조합 매입부가세 관련 소송",
                        "일조 침해 관련 손해배상 소송",
                        "보존, 이전, 신탁 등 각종 부동산 등기",
                    ],
                },
            ],       
            precedent: [
                "응암3주택재건축정비사업조합",
                "남산 4-4지구 주택재개발정비사업조합",
                "전농제7구역 주택재개발정비사업조합",
                "삼호동호진주 가로주택정비사업조합",
                "백운연립2단지 주택재건축 정비사업조합",
                "태평1구역 주택재개발정비사업조합",
                "신정2재정비촉진구역1지구 주택재개발정비사업조합",
                "온천2구역 주택재개발정비사업조합",
                "율량사천구역 주택재건축 정비사업조합",
                "흑석3재정비촉진구역 주택재개발정비사업조합",
                "성남중1 주택재개발정비사업조합",
                "염주주공아파트 주택재개발정비사업조합",
                "111구역정자지구 주택개재개발정비사업조합",
                "광명 제15R구역 주택재개발정비사업조합",
                "선화재정비촉진구역 주택재개발정비사업조합",
                "상록주택재개발정비사업조합",
                "삼익2차아파트 리모델링주택조합",
                "원일빌라주택재건축정비사업조합",
            ]
        }
    },
};
</script>

<style lang="scss" scoped>
</style>
