<template>
    <page-section :tit="title" titAdd="Contact Us" class="text-bg" style="background-image: url(/images/sub/contact/contact-bg.svg);">
        <v-card data-aos="fade-up" rounded class="mb-40px mb-md-80px">
            <iframe class="d-block" :src="iframe" width="100%" height="600" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" />
        </v-card>
        <v-divider v-if="!$vuetify.breakpoint.smAndUp" />
        <v-row no-gutters class="text-center">
            <v-col cols="12" sm="4" :class="$vuetify.breakpoint.smAndUp ? 'border-r' : 'border-b'">
                <v-card data-aos="fade-up" :href="addressLink" target="_blank" class="v-card--none-active-bg">
                    <div class="pa-30px py-sm-0 px-lg-40px">
                        <v-img src="/images/sub/contact/contact-icon.svg" max-width="60" :aspect-ratio="1 / 1" class="mx-auto"/>
                        <tit class="tit--sm font-secondary mt-12px mt-md-24px">Address</tit>
                        <txt class="txt--dark mt-4px mt-md-8px">{{address}}</txt>
                    </div>
                </v-card>
            </v-col>
            <v-col cols="12" sm="4" :class="$vuetify.breakpoint.smAndUp ? 'border-r' : 'border-b'">
                <v-card data-aos="fade-up" data-aos-delay="100" :href="'tel:'+tel" target="_blank" class="v-card--none-active-bg">
                    <div class="pa-30px py-sm-0 px-lg-40px">
                        <v-img src="/images/sub/contact/contact-icon2.svg" max-width="60" :aspect-ratio="1 / 1" class="mx-auto"/>
                        <tit class="tit--sm font-secondary mt-12px mt-md-24px">Tel</tit>
                        <txt class="txt--dark mt-4px mt-md-8px">{{tel}}</txt>
                    </div>
                </v-card>
            </v-col>
            <v-col cols="12" sm="4">
                <v-card data-aos="fade-up" data-aos-delay="200" class="v-card--none-active-bg">
                    <div class="pa-30px py-sm-0 px-lg-40px">
                        <v-img src="/images/sub/contact/contact-icon3.svg" max-width="60" :aspect-ratio="1 / 1" class="mx-auto"/>
                        <tit class="tit--sm font-secondary mt-12px mt-md-24px">Fax</tit>
                        <txt class="txt--dark mt-4px mt-md-8px">{{fax}}</txt>
                    </div>
                </v-card>
            </v-col>
        </v-row>
    </page-section>
</template>

<script>
import PageSection from "@/sets/styles/pages/page-section.vue";

import Tit from "@/components/publish/styles/typography/tit.vue";
import Txt from "@/components/publish/styles/typography/txt.vue";
export default {
    props: {
        title: { type: String, default: "" },
        iframe: { type: String, default: "" },
        address: { type: String, default: "" },
        addressLink: { type: String, default: "" },
        tel: { type: String, default: "" },
        fax: { type: String, default: "" },
    },
    components: {
        PageSection,

        Tit,
        Txt,
    },
}
</script>

<style lang="scss" scoped>
</style>