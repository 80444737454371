var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card-row-wrap"
  }, [_c('v-row', {
    staticClass: "row--xl ma-xl-n50px card-row",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "pa-xl-50px",
    attrs: {
      "data-aos": "fade-left",
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-responsive', {
    staticClass: "card-row__image-wrap",
    attrs: {
      "aspect-ratio": 670 / 500
    }
  }, [_c('v-img', {
    staticClass: "card-row__image w-100",
    attrs: {
      "src": _vm.image,
      "aspect-ratio": 870 / 500,
      "height": "100%"
    }
  })], 1)], 1), _c('v-col', {
    staticClass: "pa-xl-50px",
    attrs: {
      "data-aos": "fade-right",
      "data-aos-delay": "100",
      "cols": "12",
      "md": "6"
    }
  }, [_c('txt', {
    staticClass: "txt--xl txt--dark line-height-17"
  }, [_vm._t("default")], 2)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }