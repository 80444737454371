<template>
    <client-page>
        <template #subHead>
            <sub-visual sh="업무분야" tabActive="건축물" bg="/images/sub/visual/sv-business.jpg" />
        </template>
        <page-section tit="건축물 하자관리" titAdd="Business" class="text-bg" style="background-image: url(/images/sub/business/business-bg.svg);">
            <div class="card-rows">
                <card-row image="/images/sub/business/building/building-img.jpg">
                    <p>
                        아파트 등 공동주택 및 건설에 관련된 분쟁은 다수의 관계자들이
                        복합적으로 연관되어 있으며 건설공사부문을 쟁점으로 하기 때문에 다른 어떤 분야보다도 고도의 전문성과 경험이 필요한 분야입니다.
                    </p>
                </card-row>
                <card-row image="/images/sub/business/building/building-img2.jpg">
                    <p>
                        아파트 하자소송 분야는 법무법인 태성과 역사를 함께하며 눈에 띄는
                        성과를 보이고 있습니다. 대한변호사협회 건설전문변호사 등록 및 건축 분야 최고 수준 자격증인 건축시공기술사, 건축기사, 토목기사를 보유한 윤영환 변호사가 주축이 되어 업무를 수행하며
                        기술자 출신 변호사로
                        하자진단 단계부터 유기적인 소통을 통해 하자보수 소송에서 최선의
                        성과를 이끌어 내고 있습니다.
                    </p>
                    <p>
                        또한 소송을 통한 하자보수 뿐만 아니라 입주민에게 최대한 이익이 될 수 있도록 시공사와 대등하며 조속한 합의를 통하여 분쟁의 조기 종결에도 집중하고 있습니다.
                    </p>
                </card-row>
                <card-row image="/images/sub/business/building/building-img3.jpg">
                    <p>
                        더 나아가 눈에 보이는 하자뿐만 아니라 도면과 달리 시공된 변경시공
                        하자, 도면에는 있으나 실제로 시공되지 않은 미시공 하자, 법규와 달리 시공된 하자까지 정밀하게 찾아내 숨겨진 입주민들의 권리까지 챙기고 있습니다.
                    </p>
                    <p>
                        아울러 입주자대표회의 구성, 위탁관리업체 선정, 그 밖에 아파트의 크고 작은 분쟁에 대하여 법률지원 사업을 시행하고 있어 입주자대표회의의 든든한 조력자로 거듭나겠습니다.
                    </p>
                </card-row>
            </div>
        </page-section>
        <page-section tit="주요 업무" titAdd="Business" dark style="background-image: url(/images/sub/business/building/building-bg.jpg);">
            <info-row v-for="(item, index) in info" :key="index" :title="item.title" :topLine="index == 0" dark>
                <info-detail v-for="(detail, index) in item.infoDetail" :key="index" :class="index !== 0 ? 'mt-10px mt-md-16px' : ''">
                    {{detail}}
                </info-detail>
            </info-row>
        </page-section>
        <page-section tit="주요 업무사례" titAdd="Business" titLeft class="text-bg--2 pb-0"  style="background-image: url(/images/sub/business/business-bg2.svg);">
        </page-section>
        <page-section class="page-section--last page-section--xs grey lighten-5">
            <v-row>
                <v-col v-for="(detail, index) in precedent" :key="index" cols="12" md="6">                    
                    <card-primary data-aos="fade-up" :data-aos-delay="$vuetify.breakpoint.mdAndUp ? index*50 : 0 ">
                        <txt class="txt--dark">{{detail}}</txt>
                    </card-primary>
                </v-col>
            </v-row>
        </page-section>
    </client-page>
</template>

<script>

import ClientPage from "@/sets/styles/apps/client-page.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import PageSection from "@/sets/styles/pages/page-section.vue";
import CardRow from "@/sets/styles/card-row.vue";
import InfoRow from "@/sets/styles/info-row.vue";
import InfoDetail from "@/sets/styles/info-detail.vue";
import CardPrimary from "@/components/publish/parents/cards/card-primary.vue";

import Tit from "@/components/publish/styles/typography/tit.vue";
import Txt from "@/components/publish/styles/typography/txt.vue";

export default {
    props: {},
    components: {
        ClientPage,
        SubVisual,
        PageSection,
        CardRow,
        InfoRow,
        InfoDetail,
        CardPrimary,

        Tit,
        Txt,
    },
    data() {
        return {           
            info: [
                {
                    title : "아파트 하자보수소송",
                    infoDetail : [
                        "아파트 하자진단업무",
                        "아파트 하자보수 청구 업무",
                        "집합건물법상 제척기간 관리 업무",
                        "하자보수비 협상업무",
                        "하자보수에 갈음한 손해배상 청구소송",
                        "하자보수보증금 청구소송",
                        "분양전환 단지의 부당이득금 반환소송",
                    ],
                },
                {
                    title : "입주자대표회의 관련 법률",
                    infoDetail : [
                        "위탁관리업체의 관리비 부당징수에 관한 반환 소송",
                        "동별대표자 선출에 관한 법률 자문 및 소송",
                        "용역업체와 분쟁에 대한 법률 자문",
                        "휘트니스 센터와 관련된 명도 소송",
                        "하자보수 합의서, 공사관련 계약서 등 검토",
                    ],
                },
            ],       
            precedent: [
                "전주 S아파트 합의 완료",
                "전주 C아파트 합의 완료",
                "포항 Y아파트 하자보수 청구에 의한 보수공사 실시",
                "경산 E아파트 하자보수 청구에 의한 보수공사 실시",
                "군포 H아파트 소송 진행으로 하자보수금 판결",
                "논산 A파크 소송 진행으로 판결",
                "전주 H아파트 소송 진행으로 하자보수금 판결",
                "송도 C빌딩 소송 진행으로 하자보수금 판결",
                "수원 H아파트 위탁관리업체 부당이득 반환청구 소송",
                "울산 E아파트 선거관리위원회 부당 운영에 대한 자문",
            ]
        }
    },
};
</script>

<style lang="scss" scoped>
</style>
