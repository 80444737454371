var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual', {
          attrs: {
            "sh": "기술사 사무소",
            "bg": "/images/sub/visual/sv-construction.jpg"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('page-section', {
    staticClass: "text-bg",
    staticStyle: {
      "background-image": "url(/images/sub/construction/construction-bg.svg)"
    },
    attrs: {
      "tit": "기술사 사무소",
      "titAdd": "Construction engineer"
    },
    scopedSlots: _vm._u([{
      key: "pageSectionImmersive",
      fn: function () {
        return [_c('div', {
          staticClass: "construction-bottom text-center"
        }, [_c('v-img', {
          staticClass: "construction-bottom__bg w-100",
          staticStyle: {
            "background-image": "url(/images/sub/construction/construction-bg2.svg)"
          },
          attrs: {
            "max-width": "1840",
            "aspect-ratio": 1840 / 330
          }
        }), _c('v-img', {
          attrs: {
            "data-aos": "fade-up",
            "src": "/images/sub/construction/construction-img.jpg",
            "max-width": "1840",
            "min-height": _vm.$vuetify.breakpoint.mdAndUp ? 380 : 180
          }
        }), _c('v-container', {
          staticClass: "mt-n40px mt-md-n86px"
        }, [_c('v-card', {
          attrs: {
            "data-aos": "fade-up",
            "data-aos-delay": "50",
            "tile": "",
            "color": "white"
          }
        }, [_c('div', {
          staticClass: "pa-20px pa-md-30px pa-lg-40"
        }, [_c('txt', {
          staticClass: "txt--xl txt--dark line-height-17"
        }, [_vm._v(" 법무법인 태성은 윤영환 대표변호사가 건축시공기술사 자격을보유하고 있고, 법무법인 태성 이름으로 기술사사무소로 등록되어 있습니다. "), _c('br'), _vm._v(" 법무법인 태성은 기술사사무소로서 아파트 안전진단, 감리, 품질점검 등 전문기술분야로도 업무범위를 확대해 나갈 예정입니다. ")])], 1)]), _c('v-card', {
          staticClass: "mt-30px mt-md-60px",
          attrs: {
            "rounded": "sm",
            "color": "grey lighten-5"
          }
        }, [_c('div', {
          staticClass: "pa-20px pa-md-30px pa-lg-40"
        }, [_c('tit', {
          staticClass: "tit--sm primary--text line-height-17",
          attrs: {
            "data-aos": "fade-up"
          }
        }, [_vm._v(" - 기술사법 제3조(기술사의 직무) 제1항 - ")]), _c('txt', {
          staticClass: "txt--dark line-height-17 mt-4px mt-md-8px",
          attrs: {
            "data-aos": "fade-up",
            "data-aos-delay": "50"
          }
        }, [_vm._v(" 기술사는 과학기술에 관한 전문적 응용능력이 필요한 사항에 대하여 계획·연구·설계·분석·조사·시험·시공·감리·평가·진단·시험운전·사업관리·기술판단(기술감정 포함)·기술중재 "), _c('br', {
          staticClass: "d-none d-xl-block"
        }), _vm._v(" 또는 이에 관한 기술자문과 기술지도를 그 직무로 한다. ")])], 1)])], 1)], 1)];
      },
      proxy: true
    }])
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }