var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual', {
          attrs: {
            "sh": "업무분야",
            "tabActive": "정비사업",
            "bg": "/images/sub/visual/sv-business.jpg"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('page-section', {
    staticClass: "text-bg",
    staticStyle: {
      "background-image": "url(/images/sub/business/business-bg.svg)"
    },
    attrs: {
      "tit": "정비사업 법률자문",
      "titAdd": "Business"
    }
  }, [_c('div', {
    staticClass: "card-rows"
  }, [_c('card-row', {
    attrs: {
      "image": "/images/sub/business/maintenance/maintenance-img.jpg"
    }
  }, [_c('p', [_vm._v(" 재개발·재건축 정비사업은 조합설립 단계에서부터 준공 후 이전고시 청산까지 긴 기간이 소요되고, 정비사업조합은 사업주체로서 시공사 선정부터 시공계획 수립 단계를 거쳐 아파트를 시공하고 완공하기까지 모든 과정에서 의사결정을 하고 현장을 관리해야 하기 때문에, 법률적인 면이나 건설기술 분야의 자문이 필수적입니다. ")])]), _c('card-row', {
    attrs: {
      "image": "/images/sub/business/maintenance/maintenance-img2.jpg"
    }
  }, [_c('p', [_vm._v(" 법무법인 태성은 건축 분야 최고 수준 자격증인 건축시공기술사와 건축기사, 토목기사 자격을 보유한 윤영환 변호사를 주축으로 다수의 건설전문변호사를 보유하고 있고, 사내 전문기술인력을 보유하고 있어 재개발 재건축 정비사업조합의 시작부터 종료까지 법률분야와 건설기술분야 전반에 대한 전문적인 조언과 컨설팅이 가능합니다. ")])]), _c('card-row', {
    attrs: {
      "image": "/images/sub/business/maintenance/maintenance-img3.jpg"
    }
  }, [_c('p', [_vm._v(" 법무법인 태성은 하자소송 분야와 신축 아파트 단체등기, 입주예정자협의회 법률자문 분야에서 업계 내 선도적인 역할을 하고 있고, 정비사업조합 업무까지 아파트와 관련된 주요 분야를 모두 수행할 수 있는 역량과 노하우를 갖추고 있습니다. ")]), _c('p', [_vm._v(" 재개발·재건축조합, 입주자대표회의, 입주예정자협의회 등 아파트 입주민의 든든한 지원자이자 최고의 파트너로서 자리매김하고자 최선을 다하겠습니다. ")])])], 1)]), _c('page-section', {
    staticStyle: {
      "background-image": "url(/images/sub/business/maintenance/maintenance-bg.jpg)"
    },
    attrs: {
      "tit": "주요 업무",
      "titAdd": "Business",
      "dark": ""
    }
  }, _vm._l(_vm.info, function (item, index) {
    return _c('info-row', {
      key: index,
      attrs: {
        "title": item.title,
        "topLine": index == 0,
        "dark": ""
      }
    }, _vm._l(item.infoDetail, function (detail, index) {
      return _c('info-detail', {
        key: index,
        class: index !== 0 ? 'mt-10px mt-md-16px' : ''
      }, [_vm._v(" " + _vm._s(detail) + " ")]);
    }), 1);
  }), 1), _c('page-section', {
    staticClass: "text-bg--2 pb-0",
    staticStyle: {
      "background-image": "url(/images/sub/business/business-bg2.svg)"
    },
    attrs: {
      "tit": "주요 업무사례",
      "titAdd": "Business",
      "titLeft": ""
    }
  }), _c('page-section', {
    staticClass: "page-section--last page-section--xs grey lighten-5"
  }, [_c('v-row', _vm._l(_vm.precedent, function (detail, index) {
    return _c('v-col', {
      key: index,
      attrs: {
        "cols": "12",
        "md": "6"
      }
    }, [_c('card-primary', {
      attrs: {
        "data-aos": "fade-up",
        "data-aos-delay": _vm.$vuetify.breakpoint.mdAndUp ? index * 50 : 0
      }
    }, [_c('txt', {
      staticClass: "txt--dark"
    }, [_vm._v(_vm._s(detail))])], 1)], 1);
  }), 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }