var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual', {
          attrs: {
            "sh": "업무분야",
            "tabActive": "건축물",
            "bg": "/images/sub/visual/sv-business.jpg"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('page-section', {
    staticClass: "text-bg",
    staticStyle: {
      "background-image": "url(/images/sub/business/business-bg.svg)"
    },
    attrs: {
      "tit": "건축물 하자관리",
      "titAdd": "Business"
    }
  }, [_c('div', {
    staticClass: "card-rows"
  }, [_c('card-row', {
    attrs: {
      "image": "/images/sub/business/building/building-img.jpg"
    }
  }, [_c('p', [_vm._v(" 아파트 등 공동주택 및 건설에 관련된 분쟁은 다수의 관계자들이 복합적으로 연관되어 있으며 건설공사부문을 쟁점으로 하기 때문에 다른 어떤 분야보다도 고도의 전문성과 경험이 필요한 분야입니다. ")])]), _c('card-row', {
    attrs: {
      "image": "/images/sub/business/building/building-img2.jpg"
    }
  }, [_c('p', [_vm._v(" 아파트 하자소송 분야는 법무법인 태성과 역사를 함께하며 눈에 띄는 성과를 보이고 있습니다. 대한변호사협회 건설전문변호사 등록 및 건축 분야 최고 수준 자격증인 건축시공기술사, 건축기사, 토목기사를 보유한 윤영환 변호사가 주축이 되어 업무를 수행하며 기술자 출신 변호사로 하자진단 단계부터 유기적인 소통을 통해 하자보수 소송에서 최선의 성과를 이끌어 내고 있습니다. ")]), _c('p', [_vm._v(" 또한 소송을 통한 하자보수 뿐만 아니라 입주민에게 최대한 이익이 될 수 있도록 시공사와 대등하며 조속한 합의를 통하여 분쟁의 조기 종결에도 집중하고 있습니다. ")])]), _c('card-row', {
    attrs: {
      "image": "/images/sub/business/building/building-img3.jpg"
    }
  }, [_c('p', [_vm._v(" 더 나아가 눈에 보이는 하자뿐만 아니라 도면과 달리 시공된 변경시공 하자, 도면에는 있으나 실제로 시공되지 않은 미시공 하자, 법규와 달리 시공된 하자까지 정밀하게 찾아내 숨겨진 입주민들의 권리까지 챙기고 있습니다. ")]), _c('p', [_vm._v(" 아울러 입주자대표회의 구성, 위탁관리업체 선정, 그 밖에 아파트의 크고 작은 분쟁에 대하여 법률지원 사업을 시행하고 있어 입주자대표회의의 든든한 조력자로 거듭나겠습니다. ")])])], 1)]), _c('page-section', {
    staticStyle: {
      "background-image": "url(/images/sub/business/building/building-bg.jpg)"
    },
    attrs: {
      "tit": "주요 업무",
      "titAdd": "Business",
      "dark": ""
    }
  }, _vm._l(_vm.info, function (item, index) {
    return _c('info-row', {
      key: index,
      attrs: {
        "title": item.title,
        "topLine": index == 0,
        "dark": ""
      }
    }, _vm._l(item.infoDetail, function (detail, index) {
      return _c('info-detail', {
        key: index,
        class: index !== 0 ? 'mt-10px mt-md-16px' : ''
      }, [_vm._v(" " + _vm._s(detail) + " ")]);
    }), 1);
  }), 1), _c('page-section', {
    staticClass: "text-bg--2 pb-0",
    staticStyle: {
      "background-image": "url(/images/sub/business/business-bg2.svg)"
    },
    attrs: {
      "tit": "주요 업무사례",
      "titAdd": "Business",
      "titLeft": ""
    }
  }), _c('page-section', {
    staticClass: "page-section--last page-section--xs grey lighten-5"
  }, [_c('v-row', _vm._l(_vm.precedent, function (detail, index) {
    return _c('v-col', {
      key: index,
      attrs: {
        "cols": "12",
        "md": "6"
      }
    }, [_c('card-primary', {
      attrs: {
        "data-aos": "fade-up",
        "data-aos-delay": _vm.$vuetify.breakpoint.mdAndUp ? index * 50 : 0
      }
    }, [_c('txt', {
      staticClass: "txt--dark"
    }, [_vm._v(_vm._s(detail))])], 1)], 1);
  }), 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }