<template>
    <client-page>
        <template #subHead>
            <sub-visual sh="업무분야" tabActive="입주예정자협의회" bg="/images/sub/visual/sv-business.jpg" />
        </template>
        <page-section tit="입주예정자협의회 컨설팅" titAdd="Business" class="text-bg" style="background-image: url(/images/sub/business/business-bg.svg);">
            <div class="card-rows">
                <card-row image="/images/sub/business/council/council-img.jpg">
                    <p>
                        최근 신축아파트들의 하자 문제가 크게 증가하면서 입주 전
                        시공단계에서부터 활동하는 입주예정자협의회의 역할도 중대해지고
                        있습니다. 많은 입주예정자협의회들이 시공사가 적합하게 공사를
                        이행하고 있는지, 변경시공 하자가 발생하지는 않는지 감독하고 있고,
                        단지 설립으로 인한 기반시설 확충 문제(초등학교 개설 문제, 교통문제, 관공서 이전 문제 등)를 해결하고자 하시지만 관련 문제에 대하여
                        경험하지 못한 부분이 많고, 법률전문가가 아니기 때문에 법률적
                        차원에서 대응하기가 쉽지 않은 상황입니다.
                    </p>
                </card-row>
                <card-row image="/images/sub/business/council/council-img2.jpg">
                    <p>
                        이에 입주예정자협의회 법률문제에 대하여 선도적인 역할을 하고 있는 법무법인 태성에서는 입주예정자협의회 분들을 위하여 '건설', '부동산'
                        전문변호사가 법률 자문을 제공하고 있습니다. 다수의 경험을 바탕으로 현재 입주예정자협의회가 처한 상황을 정확하게 분석하여 명쾌한 조언을 드립니다.
                    </p>
                </card-row>
                <card-row image="/images/sub/business/council/council-img3.jpg">
                    <p>
                        더 나아가 계약해지, 합의 등의 법률적 이슈가 발생할 시 기존 사례를
                        바탕으로 실질적으로 도움을 드리며 사업주체와 협상 시에도 적극적으로 참여하여 입주예정자협의회를 지원합니다.
                    </p>
                    <p>
                        저희 법무법인 태성은 입주예정자협의회 업무에 대해 보유하고 있는
                        다년간의 경험과 노하우를 통하여 든든한 지원군이 되겠습니다.
                    </p>
                </card-row>
            </div>
        </page-section>
        <page-section tit="주요 업무" titAdd="Business" dark style="background-image: url(/images/sub/business/council/council-bg.jpg);">
            <info-row v-for="(item, index) in info" :key="index" :title="item.title" :topLine="index == 0" dark>
                <info-detail v-for="(detail, index) in item.infoDetail" :key="index" :class="index !== 0 ? 'mt-10px mt-md-16px' : ''">
                    {{detail}}
                </info-detail>
            </info-row>
        </page-section>
        <page-section tit="주요 업무사례" titAdd="Business" titLeft class="text-bg--2 pb-0"  style="background-image: url(/images/sub/business/business-bg2.svg);">
        </page-section>
        <page-section class="page-section--last page-section--xs grey lighten-5">
            <v-row>
                <v-col v-for="(detail, index) in precedent" :key="index" cols="12" md="6">                    
                    <card-primary data-aos="fade-up" :data-aos-delay="$vuetify.breakpoint.mdAndUp ? index*50 : 0 ">
                        <txt class="txt--dark">{{detail}}</txt>
                    </card-primary>
                </v-col>
            </v-row>
        </page-section>
    </client-page>
</template>

<script>

import ClientPage from "@/sets/styles/apps/client-page.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import PageSection from "@/sets/styles/pages/page-section.vue";
import CardRow from "@/sets/styles/card-row.vue";
import InfoRow from "@/sets/styles/info-row.vue";
import InfoDetail from "@/sets/styles/info-detail.vue";
import CardPrimary from "@/components/publish/parents/cards/card-primary.vue";

import Tit from "@/components/publish/styles/typography/tit.vue";
import Txt from "@/components/publish/styles/typography/txt.vue";

export default {
    props: {},
    components: {
        ClientPage,
        SubVisual,
        PageSection,
        CardRow,
        InfoRow,
        InfoDetail,
        CardPrimary,

        Tit,
        Txt,
    },
    data() {
        return {           
            info: [
                {
                    title : "입주예정자협의회 컨설팅 관련",
                    infoDetail : [
                        "도시형생활주택 및 오피스텔 등 관리단 구성 업무",
                        "입주예정자협의회 차원의 입주컨설팅 제공",
                        "입주지연, 학교 용지부담금 등 무료법률 자문",
                        "입주지연, 학교 용지부담금 등 무료법률 자문",
                        "입주예정자 협의회 활동과정에 대한 법률지원",
                    ],
                },
            ],       
            precedent: [
                "제천 S 아파트 - 입주협상, 지체상금, 계약해지 등 법률자문 제공",
                "수원 H 아파트 - 도로교통시설 협상, 하자 법률자문 제공",
                "광주 H 아파트 - 단지통합, 지구 내 학군, 교통 등 법률 자문 제공",
                "울산 A 아파트 - 임시통학로 사용 합의서 검토, 하자 법률자문 제공",
                "위례 G 오피스텔 - 관리단 구성에 필요한 지원 등 법률 자문 제공",
                "광주 C 아파트 - 학교분담금, 셔틀버스관련 등 법률자문 제공",
                "김포 B 아파트 - 지하주차장 사용문제, 관리비 부당이득금 반환청구 등 법률 자문 제공",
                "원주 V 아파트 - 도시계획 상 도로, 녹지 변경 및 주유소 거리 등 법률 자문 제공",
                "평택 J 아파트 - 교차로 진입로 신설 및 발코니 면적 문제 등 법률 자문 제공",
                "고양 H 오피스텔 - 관리단 구성에 필요한 지원 등 법률 자문 제공",
                "고양 A 아파트 - 창호의 크기 상이한 부분 법률 자문 제공",
                "동탄 J 아파트 - 저층부 추가 테라스 및 어닝설치 등 법률 자문 제공",
                "시흥 A 아파트 - 시흥시 도시계획 조례개정에 대한 법률 자문 제공 “일부개정조례안” 통과",
            ]
        }
    },
};
</script>

<style lang="scss" scoped>
</style>
