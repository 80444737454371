<template>
    <client-page>
        <template #subHead>
            <sub-visual sh="Lawfirm taesung & P.E" tabActive="변호사 소개" bg="/images/sub/visual/sv-introduction.jpg" />
        </template>
        <page-section tit="변호사 소개" titAdd="Introduction" class="lawyer-view__head" titClass="pb-0">
            <template #pageSectionImmersive>
                <v-img src="/images/sub/introduction/lawyer/lawyer-bg.svg" max-width="1840" :aspect-ratio="1840 / 266" class="text-bg w-100" />
            </template>
        </page-section>
        <page-section class="lawyer-view__body page-section--sm grey darken-3">
            <v-row>
                <v-col cols="12" sm="7">
                    <div class="lawyer-view__body-contents">
                        <tit data-aos="fade-up" class="lawyer-view__body-tit tit--lg white--text">
                            법무법인 태성의 변호사는 <br>
                            언제나 여러분 곁에 함께 하겠습니다.
                        </tit>
                        <div>
                            <v-row align="center">
                                <v-col cols="12">
                                    <tit data-aos="fade-up" data-aos-delay="100" class="tit--sm primary--text line-height-1 font-secondary">{{this.lawyer.titleEn}}</tit>
                                </v-col>
                                <v-col cols="auto">
                                    <tit data-aos="fade-up" data-aos-delay="200" class="tit--xxl white--text">{{this.lawyer.title}}</tit>
                                </v-col>
                                <v-col cols="auto">
                                    <txt data-aos="fade-up" data-aos-delay="300" class="txt--xl white--text"><span v-if="this.lawyer.ceo">대표 </span>변호사</txt>
                                </v-col>
                            </v-row>
                        </div>
                    </div>
                </v-col>
                <v-col cols="8" sm="5" offset="4" offset-sm="7" offset-md="0">
                    <div class="lawyer-img-wrap">
                        <v-img data-aos="fade-left" :src="'/images/lawyer/view/'+this.lawyer.title+'.png'" max-width="586" :aspect-ratio="586 / 900" class="lawyer-img w-100"/>
                    </div>
                </v-col>
            </v-row>
        </page-section>
        <page-section v-if="this.lawyer.info" class="lawyer-view__foot page-section--last page-section--xs">
            <info-row v-for="(info, index) in this.lawyer.info" :key="index" :title="info.title" :topLine="index == 0">
                <info-detail v-for="(detail, index) in info.infoDetail" :key="index" :class="index !== 0 ? 'mt-10px mt-md-16px' : ''">
                    <span v-if="typeof detail === 'string'">{{ detail }}</span>
                    <strong v-else-if="detail.bold">{{ detail.txt }}</strong>
                </info-detail>
            </info-row>
        </page-section>
    </client-page>
</template>

<script>
import { lawyer } from "@/assets/data/lawyer.js";
import ClientPage from "@/sets/styles/apps/client-page.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import PageSection from "@/sets/styles/pages/page-section.vue";
import InfoRow from "@/sets/styles/info-row.vue";
import InfoDetail from "@/sets/styles/info-detail.vue";

import Tit from "@/components/publish/styles/typography/tit.vue";
import Txt from "@/components/publish/styles/typography/txt.vue";

export default {
    props: {
        lawyerId: String,
    },
    components: {
        ClientPage,
        SubVisual,
        PageSection,
        InfoRow,
        InfoDetail,

        Tit,
        Txt,
    },
    data() {
        return { 
            lawyer: null,
        }
    },
    created() {
        this.lawyer = lawyer[this.lawyerId];
    },
};
</script>

<style lang="scss" scoped>
.lawyer-view{
    &__body{
        .lawyer-img{
            margin-bottom: calc(var(--page-section-padding-y-sm) * -1);
        }
        &-contents{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
        &-tit{
            padding-bottom: var(--tit-wrap-padding-bottom-lg);
        }
    }
}
@media (min-width:576px){
    .lawyer-view{
        &__body{
            .lawyer-img{
                margin-bottom: 0;
                bottom: calc(var(--page-section-padding-y-sm) * -1);
            }
        }
    }
}
@media (min-width:768px){
}
@media (min-width:1024px){
    .lawyer-view{
        &__body{
            &-contents{
                min-height: 536px;
            }
        }
    }
}
@media (min-width:1200px){
}
</style>
