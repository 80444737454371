var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-row', {
    staticClass: "my-md-n30px"
  }, _vm._l(_vm.boards, function (board) {
    return _c('v-col', {
      key: board._id,
      staticClass: "py-md-30px",
      attrs: {
        "cols": "6",
        "md": "4",
        "lg": "3"
      }
    }, [_c('story-item', {
      attrs: {
        "to": `/story/${board === null || board === void 0 ? void 0 : board._id}`,
        "image": board === null || board === void 0 ? void 0 : board.thumb
      },
      scopedSlots: _vm._u([{
        key: "TxtAdd",
        fn: function () {
          return [_vm._v(_vm._s(board === null || board === void 0 ? void 0 : board.summary))];
        },
        proxy: true
      }], null, true)
    }, [_vm._v(" " + _vm._s(board === null || board === void 0 ? void 0 : board.subject) + " ")])], 1);
  }), 1), _c('div', {
    staticClass: "v-pagination-wrap"
  }, [_c('v-pagination', {
    attrs: {
      "length": _vm.pageCount,
      "total-visible": 11
    },
    on: {
      "input": function ($event) {
        return _vm.saerch();
      }
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }