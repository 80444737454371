var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual', {
          attrs: {
            "sh": "업무분야",
            "tabActive": "입주예정자협의회",
            "bg": "/images/sub/visual/sv-business.jpg"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('page-section', {
    staticClass: "text-bg",
    staticStyle: {
      "background-image": "url(/images/sub/business/business-bg.svg)"
    },
    attrs: {
      "tit": "입주예정자협의회 컨설팅",
      "titAdd": "Business"
    }
  }, [_c('div', {
    staticClass: "card-rows"
  }, [_c('card-row', {
    attrs: {
      "image": "/images/sub/business/council/council-img.jpg"
    }
  }, [_c('p', [_vm._v(" 최근 신축아파트들의 하자 문제가 크게 증가하면서 입주 전 시공단계에서부터 활동하는 입주예정자협의회의 역할도 중대해지고 있습니다. 많은 입주예정자협의회들이 시공사가 적합하게 공사를 이행하고 있는지, 변경시공 하자가 발생하지는 않는지 감독하고 있고, 단지 설립으로 인한 기반시설 확충 문제(초등학교 개설 문제, 교통문제, 관공서 이전 문제 등)를 해결하고자 하시지만 관련 문제에 대하여 경험하지 못한 부분이 많고, 법률전문가가 아니기 때문에 법률적 차원에서 대응하기가 쉽지 않은 상황입니다. ")])]), _c('card-row', {
    attrs: {
      "image": "/images/sub/business/council/council-img2.jpg"
    }
  }, [_c('p', [_vm._v(" 이에 입주예정자협의회 법률문제에 대하여 선도적인 역할을 하고 있는 법무법인 태성에서는 입주예정자협의회 분들을 위하여 '건설', '부동산' 전문변호사가 법률 자문을 제공하고 있습니다. 다수의 경험을 바탕으로 현재 입주예정자협의회가 처한 상황을 정확하게 분석하여 명쾌한 조언을 드립니다. ")])]), _c('card-row', {
    attrs: {
      "image": "/images/sub/business/council/council-img3.jpg"
    }
  }, [_c('p', [_vm._v(" 더 나아가 계약해지, 합의 등의 법률적 이슈가 발생할 시 기존 사례를 바탕으로 실질적으로 도움을 드리며 사업주체와 협상 시에도 적극적으로 참여하여 입주예정자협의회를 지원합니다. ")]), _c('p', [_vm._v(" 저희 법무법인 태성은 입주예정자협의회 업무에 대해 보유하고 있는 다년간의 경험과 노하우를 통하여 든든한 지원군이 되겠습니다. ")])])], 1)]), _c('page-section', {
    staticStyle: {
      "background-image": "url(/images/sub/business/council/council-bg.jpg)"
    },
    attrs: {
      "tit": "주요 업무",
      "titAdd": "Business",
      "dark": ""
    }
  }, _vm._l(_vm.info, function (item, index) {
    return _c('info-row', {
      key: index,
      attrs: {
        "title": item.title,
        "topLine": index == 0,
        "dark": ""
      }
    }, _vm._l(item.infoDetail, function (detail, index) {
      return _c('info-detail', {
        key: index,
        class: index !== 0 ? 'mt-10px mt-md-16px' : ''
      }, [_vm._v(" " + _vm._s(detail) + " ")]);
    }), 1);
  }), 1), _c('page-section', {
    staticClass: "text-bg--2 pb-0",
    staticStyle: {
      "background-image": "url(/images/sub/business/business-bg2.svg)"
    },
    attrs: {
      "tit": "주요 업무사례",
      "titAdd": "Business",
      "titLeft": ""
    }
  }), _c('page-section', {
    staticClass: "page-section--last page-section--xs grey lighten-5"
  }, [_c('v-row', _vm._l(_vm.precedent, function (detail, index) {
    return _c('v-col', {
      key: index,
      attrs: {
        "cols": "12",
        "md": "6"
      }
    }, [_c('card-primary', {
      attrs: {
        "data-aos": "fade-up",
        "data-aos-delay": _vm.$vuetify.breakpoint.mdAndUp ? index * 50 : 0
      }
    }, [_c('txt', {
      staticClass: "txt--dark"
    }, [_vm._v(_vm._s(detail))])], 1)], 1);
  }), 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }