<template>
    <client-page>
        <template #subHead>
            <sub-visual sh="기술사 사무소" bg="/images/sub/visual/sv-construction.jpg" />
        </template>
        <page-section tit="기술사 사무소" titAdd="Construction engineer" class="text-bg" style="background-image: url(/images/sub/construction/construction-bg.svg);">
            <template #pageSectionImmersive>
                <div class="construction-bottom text-center">
                    <v-img style="background-image: url(/images/sub/construction/construction-bg2.svg);" max-width="1840" :aspect-ratio="1840 / 330" class="construction-bottom__bg w-100" />
                    <v-img data-aos="fade-up" src="/images/sub/construction/construction-img.jpg" max-width="1840" :min-height="$vuetify.breakpoint.mdAndUp ? 380 : 180" />
                    <v-container class="mt-n40px mt-md-n86px">
                        <v-card data-aos="fade-up" data-aos-delay="50" tile color="white">
                            <div class="pa-20px pa-md-30px pa-lg-40">
                                <txt class="txt--xl txt--dark line-height-17">
                                    법무법인 태성은 윤영환 대표변호사가 건축시공기술사 자격을보유하고 있고, 법무법인 태성 이름으로 기술사사무소로 등록되어 있습니다. <br>
                                    법무법인 태성은 기술사사무소로서 아파트 안전진단, 감리, 품질점검 등 전문기술분야로도 업무범위를 확대해 나갈 예정입니다.
                                </txt>
                            </div>
                        </v-card>
                        <v-card rounded="sm" color="grey lighten-5" class="mt-30px mt-md-60px">
                            <div class="pa-20px pa-md-30px pa-lg-40">
                                <tit data-aos="fade-up" class="tit--sm primary--text line-height-17">
                                    - 기술사법 제3조(기술사의 직무) 제1항 -
                                </tit>
                                <txt data-aos="fade-up" data-aos-delay="50" class="txt--dark line-height-17 mt-4px mt-md-8px">
                                    기술사는 과학기술에 관한 전문적 응용능력이 필요한 사항에 대하여 계획·연구·설계·분석·조사·시험·시공·감리·평가·진단·시험운전·사업관리·기술판단(기술감정 포함)·기술중재 <br class="d-none d-xl-block" />
                                    또는 이에 관한 기술자문과 기술지도를 그 직무로 한다.
                                </txt>
                            </div>
                        </v-card>
                    </v-container>
                </div>
            </template>
        </page-section>
    </client-page>
</template>

<script>

import ClientPage from "@/sets/styles/apps/client-page.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import PageSection from "@/sets/styles/pages/page-section.vue";

import Tit from "@/components/publish/styles/typography/tit.vue";
import Txt from "@/components/publish/styles/typography/txt.vue";

export default {
    props: {},
    components: {
        ClientPage,
        SubVisual,
        PageSection,

        Tit,
        Txt,
    },
    data() {
        return {
        }
    },
};
</script>

<style lang="scss" scoped>
.construction-bottom{
    position: relative;
    &__bg{
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: -1;
        transform: translateY(var(--page-section-padding-y));
    }
}
</style>
