var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "page-section"
  }, [_c('v-container', [_vm.tit ? _c('tit-wrap-primary', {
    class: _vm.titLeft ? _vm.titClass : _vm.titClass + ' text-center',
    attrs: {
      "dark": _vm.dark
    },
    scopedSlots: _vm._u([{
      key: "TitAdd",
      fn: function () {
        return [_vm._v(_vm._s(_vm.titAdd))];
      },
      proxy: true
    }], null, false, 3615817904)
  }, [_vm._v(" " + _vm._s(_vm.tit) + " ")]) : _vm._e(), _vm._t("default")], 2), this.$slots['pageSectionImmersive'] ? _vm._t("pageSectionImmersive") : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }