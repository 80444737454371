<template>
    <client-page>
        <template #subHead>
            <sub-visual sh="오시는 길" tabActive="서울주사무소" bg="/images/sub/visual/sv-contact.jpg" />
        </template>
        <contact-info 
            title="서울주사무소"
            iframe="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3166.0946870448515!2d127.03443947635901!3d37.482091878964916!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357ca17292eb4a5b%3A0x7cd0a93f7b4bff0c!2z67KV66y067KV7J247YOc7ISxIOyEnOyauOyjvOyCrOustOyGjA!5e0!3m2!1sko!2skr!4v1692692195656!5m2!1sko!2skr"
            address="서울 서초구 강남대로 202, 모산빌딩 3층"
            addressLink="https://goo.gl/maps/WsqFk6mTHS9iMxnWA"
            tel="02-573-1041"
            fax="0303-3130-1041" />
        <contact-slide>
            <v-img src="/images/sub/contact/seoul/seoul-img.jpg" max-width="952" :aspect-ratio="952 / 634" class="w-100" />
        </contact-slide>
    </client-page>
</template>

<script>

import ClientPage from "@/sets/styles/apps/client-page.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import PageSection from "@/sets/styles/pages/page-section.vue";
import ContactInfo from "@/sets/styles/contact-info.vue";
import ContactSlide from "@/sets/styles/contact-slide.vue";

import Tit from "@/components/publish/styles/typography/tit.vue";
import Txt from "@/components/publish/styles/typography/txt.vue";

export default {
    props: {},
    components: {
        ClientPage,
        SubVisual,
        PageSection,
        ContactInfo,
        ContactSlide,

        Tit,
        Txt,
    },
    data() {
        return {
        }
    },
};
</script>

<style lang="scss" scoped>
</style>
