var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual', {
          attrs: {
            "sh": "오시는 길",
            "tabActive": "서울주사무소",
            "bg": "/images/sub/visual/sv-contact.jpg"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('contact-info', {
    attrs: {
      "title": "서울주사무소",
      "iframe": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3166.0946870448515!2d127.03443947635901!3d37.482091878964916!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357ca17292eb4a5b%3A0x7cd0a93f7b4bff0c!2z67KV66y067KV7J247YOc7ISxIOyEnOyauOyjvOyCrOustOyGjA!5e0!3m2!1sko!2skr!4v1692692195656!5m2!1sko!2skr",
      "address": "서울 서초구 강남대로 202, 모산빌딩 3층",
      "addressLink": "https://goo.gl/maps/WsqFk6mTHS9iMxnWA",
      "tel": "02-573-1041",
      "fax": "0303-3130-1041"
    }
  }), _c('contact-slide', [_c('v-img', {
    staticClass: "w-100",
    attrs: {
      "src": "/images/sub/contact/seoul/seoul-img.jpg",
      "max-width": "952",
      "aspect-ratio": 952 / 634
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }