<template>
    <client-page>
        <template #subHead>
            <sub-visual sh="Lawfirm taesung & P.E" tabActive="아파트팀 소개" bg="/images/sub/visual/sv-introduction.jpg" />
        </template>
        <page-section tit="아파트팀 소개" titAdd="Introduction" class="text-bg" style="background-image: url(/images/sub/introduction/center/center-bg.svg);">
            <card-row image="/images/sub/introduction/center/center-img.jpg" class="mb-50px mb-md-70px mb-lg-80px mb-xl-70px">
                <p>
                    아파트 입주민(소유자)은 아파트를 소유하기 전부터 소유한 이후까지
                    여러 법률분쟁을 겪게 됩니다. 아파트 건설 단계에서는 수분양자로서
                    하자예방, 성실시공을 추구하는 활동을 하고, 아파트 완공 후 입주를 한 이후에는 시공상 하자에 대응합니다. 그리고 재개발 재건축 조합의 경우조합 구성부터 해산까지 전 과정에 걸쳐서 법률자문이 필요합니다.
                </p>
                <p>
                    법무법인 태성은 아파트 소유 전부터 소유자가 된 이후까지 아파트
                    입주민에게 필요한 각종 법률 서비스를 구축하고자 노력하고 있고 각
                    분야에서 성과를 내고 있습니다.
                </p>
            </card-row>
            <div v-for="(item, index) in info" :key="index">
                <v-divider v-if="index == 0" />
                <div class="py-30px py-md-60px">
                    <v-row :class="{ 'flex-md-row-reverse': index % 2 == 0 }">
                        <v-col cols="auto" md="auto" class="mb-10px mb-md-0">
                            <v-img data-aos="zoom-out" data-aos-delay="150" :src="'/images/sub/introduction/center/0'+(index+1)+'.svg'" :aspect-ratio="378 / 252" :max-width="$vuetify.breakpoint.mdAndUp ? 378 : 120" contain />
                        </v-col>
                        <v-col cols="auto" md="">
                            <v-sheet max-width="670" :class="{ 'ml-md-auto': index % 2 !== 0 }">
                                <v-card data-aos="fade-up" rounded class="mb-30px mb-md-60px">
                                    <v-img :src="item.image" :aspect-ratio="670 / 446" class="w-100"/>
                                </v-card>
                                <txt data-aos="fade-up" class="txt--xl txt--dark line-height-17">
                                    {{item.txt}}
                                </txt>
                            </v-sheet>
                        </v-col>
                    </v-row>
                </div>            
                <v-divider />
            </div>
        </page-section>
        <page-section tit="태성의 역량 및 노하우" titAdd="Introduction" dark style="background-image: url(/images/sub/introduction/center/center-bg2.jpg);">
            <v-row justify="center" class="ma-lg-0 row-cols-lg-5">
                <v-col v-for="(item, index) in ability" :key="index" data-aos="fade-up" :data-aos-delay="$vuetify.breakpoint.mdAndUp ? index*50 : 0 "
                cols="6" md="4" :class="{ 'border-lg-l': index !== 0 }" class="pa-lg-0" style="border-color: rgba(255, 255, 255, .2) !important">
                    <div class="w-100 px-16px px-md-20px px-lg-40px d-flex flex-column align-center text-center">
                        <v-img :src="item.icon" :width="$vuetify.breakpoint.mdAndUp ? 80 : 68" :aspect-ratio="1 / 1" contain />
                        <tit class="tit--sm white--text mt-12px mt-md-24px">{{item.title}}</tit>
                    </div>
                </v-col>
            </v-row>
            <v-card rounded color="grey darken-4" class="mt-30px mt-md-60px">
                <div class="pa-20px pa-md-30px px-lg-40px py-lg-80px d-flex flex-column align-center text-center">
                    <v-img data-aos="fade-up" src="/images/symbol.svg" width="92" :aspect-ratio="92 / 80" />
                    <span data-aos="fade-up" data-aos-delay="50" class="line my-12px my-md-24px"></span>
                    <tit data-aos="fade-up" data-aos-delay="100" class="tit--sm white--text mb-8px mb-md-16px">
                        재개발·재건축조합, 입주자대표회의, 입주예정자협의회 등 입주민 분들의 <br class="d-none d-lg-block">
                        <span class="primary--text">든든한 지원자이자 최고의 파트너</span>로서 자리매김하고자 최선을 다하겠습니다.
                    </tit>
                    <txt data-aos="fade-up" data-aos-delay="150" class="white--text line-height-17">
                        대한변호사협회 건설전문변호사 등록 및 건축 분야 최고 수준 자격증인 건축시공기술사, 건축기사, 토목기사를 보유한 윤영환 변호사가 주축이 되어 업무를 수행하며 <br class="d-none d-lg-block">
                        기술자 출신 변호사로 하자진단 단계부터 유기적인 소통을 통해 하자보수 소송에서 최선의 성과를 이끌어 냅니다.
                    </txt>
                </div>
            </v-card>
        </page-section>
    </client-page>
</template>

<script>

import ClientPage from "@/sets/styles/apps/client-page.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import PageSection from "@/sets/styles/pages/page-section.vue";
import CardRow from "@/sets/styles/card-row.vue";

import Tit from "@/components/publish/styles/typography/tit.vue";
import Txt from "@/components/publish/styles/typography/txt.vue";

export default {
    props: {},
    components: {
        ClientPage,
        SubVisual,
        PageSection,
        CardRow,

        Tit,
        Txt,
    },
    data() {
        return {           
            info: [
                {
                    txt : "입주예정자(수분양자) 단계에서는 입주예정자협의회에 법률자문을 하면서 성실시공, 하자예방 활동을 돕고 입주 후 각 세대의 소유권 이전등기나 근저당권 설정등기 등 등기업무를 수행",
                    image : "/images/sub/introduction/center/center-img2.jpg",
                },
                {
                    txt : "입주 후 발생하는 시공상 하자에 대해서는 입주자대표회의의 법률고문으로 활동하면서 하자보수를 촉구하고 합의를 이루거나 소송을 통해 분쟁을 해결",
                    image : "/images/sub/introduction/center/center-img3.jpg",
                },
                {
                    txt : "재개발 재건축 조합의 경우에는 조합설립 단계에서부터 준공 후 이전 고시 청산까지 전 과정을 법률자문하면서 각종 소송, 등기 업무를 수행",
                    image : "/images/sub/introduction/center/center-img4.jpg",
                },
            ],           
            ability: [
                {
                    title : "입주자대표회 법률자문",
                    icon : "/images/sub/introduction/center/center-icon.svg",
                },
                {
                    title : "하자소송 분야",
                    icon : "/images/sub/introduction/center/center-icon2.svg",
                },
                {
                    title : "신축 아파트 단체등기",
                    icon : "/images/sub/introduction/center/center-icon3.svg",
                },
                {
                    title : "입주예정자협의회 법률자문",
                    icon : "/images/sub/introduction/center/center-icon4.svg",
                },
                {
                    title : "정비사업조합 업무",
                    icon : "/images/sub/introduction/center/center-icon5.svg",
                },
            ],
        }
    },
};
</script>

<style lang="scss" scoped>
</style>
