<template>
    <div>
        <v-row class="my-md-n30px">
            <!-- S: 프로젝트 -->
            <v-col v-for="board in boards" :key="board._id" cols="6" md="4" lg="3" class="py-md-30px">
                <project-item :to="`/project/${board?._id}`" :image="board?.thumb">
                    {{ board?.subject }}
                    <template #TxtAdd>{{ board?.summary }}</template>
                </project-item>
            </v-col>
            <!-- E: 프로젝트 -->
        </v-row>
        <div class="v-pagination-wrap">
            <v-pagination v-model="page" :length="pageCount" :total-visible="11" @input="saerch()"/>
        </div>        
    </div>
</template>

<script>
import api from "@/api"
import ProjectItem from "@/components/client/project/project-item.vue";
import Tit from "@/components/publish/styles/typography/tit.vue";
import Txt from "@/components/publish/styles/typography/txt.vue";

export default {
    components: {
        ProjectItem,
        Tit,
        Txt,
    },
    async mounted() {
        await this.init()
    },
    data() {
        return {
            boards: [],

            filter: {
                code: "project",
            },

            page: 1,
            limit: 12,
            pageCount: 1,
        }
    },
    methods: {
        async init() {
            await this.saerch();
        },
        async saerch() {
            let { summary, boards } = await api.v1.boards.gets({
                headers: {
                    skip: (this.page - 1) * this.limit,
                    limit: this.limit
                },
                params: this.filter,
            });
            this.boards = boards;
            this.pageCount = Math.ceil(summary.totalCount / this.limit);
        }
    }
}
</script>

<style>

</style>