<template>
    <client-page>
        <template #subHead>
            <sub-visual sh="실적" bg="/images/sub/visual/sv-project.jpg" />
        </template>
        <page-section tit="실적" titAdd="Project">
            <project-list data-aos="fade-up" />
        </page-section>
    </client-page>
</template>

<script>

import ClientPage from "@/sets/styles/apps/client-page.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import PageSection from "@/sets/styles/pages/page-section.vue";
import ProjectList from "@/components/client/project/project-list.vue";

import Tit from "@/components/publish/styles/typography/tit.vue";
import Txt from "@/components/publish/styles/typography/txt.vue";

export default {
    props: {},
    components: {
        ClientPage,
        SubVisual,
        PageSection,
        ProjectList,

        Tit,
        Txt,
    },
    data() {
        return {
        }
    },
};
</script>

<style lang="scss" scoped>
</style>
