var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual', {
          attrs: {
            "sh": "실적",
            "bg": "/images/sub/visual/sv-project.jpg"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('page-section', {
    attrs: {
      "tit": "실적",
      "titAdd": "Project"
    }
  }, [_c('project-list', {
    attrs: {
      "data-aos": "fade-up"
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }