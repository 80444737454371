var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual', {
          attrs: {
            "sh": "오시는 길",
            "tabActive": "인천분사무소",
            "bg": "/images/sub/visual/sv-contact.jpg"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('contact-info', {
    attrs: {
      "title": "인천분사무소",
      "iframe": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3166.9286651850134!2d126.68523648892224!3d37.46240615807739!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357b795c00e3a849%3A0xd44ce7af3b2289d6!2z67KV66y067KV7J247YOc7ISx!5e0!3m2!1sko!2skr!4v1691657442002!5m2!1sko!2skr",
      "address": "인천 미추홀구 경원대로 892, 대동빌딩 1층, 5층",
      "addressLink": "https://goo.gl/maps/M5sStAuYzPDBtCZx9",
      "tel": "032-716-9290",
      "fax": "032-876-9290"
    }
  }), _c('contact-slide', [_c('v-img', {
    staticClass: "w-100",
    attrs: {
      "src": "/images/sub/contact/incheon/incheon-img.jpg",
      "max-width": "952",
      "aspect-ratio": 952 / 634
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }