var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual', {
          attrs: {
            "sh": "Lawfirm taesung & P.E",
            "tabActive": "아파트팀 소개",
            "bg": "/images/sub/visual/sv-introduction.jpg"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('page-section', {
    staticClass: "text-bg",
    staticStyle: {
      "background-image": "url(/images/sub/introduction/center/center-bg.svg)"
    },
    attrs: {
      "tit": "아파트팀 소개",
      "titAdd": "Introduction"
    }
  }, [_c('card-row', {
    staticClass: "mb-50px mb-md-70px mb-lg-80px mb-xl-70px",
    attrs: {
      "image": "/images/sub/introduction/center/center-img.jpg"
    }
  }, [_c('p', [_vm._v(" 아파트 입주민(소유자)은 아파트를 소유하기 전부터 소유한 이후까지 여러 법률분쟁을 겪게 됩니다. 아파트 건설 단계에서는 수분양자로서 하자예방, 성실시공을 추구하는 활동을 하고, 아파트 완공 후 입주를 한 이후에는 시공상 하자에 대응합니다. 그리고 재개발 재건축 조합의 경우조합 구성부터 해산까지 전 과정에 걸쳐서 법률자문이 필요합니다. ")]), _c('p', [_vm._v(" 법무법인 태성은 아파트 소유 전부터 소유자가 된 이후까지 아파트 입주민에게 필요한 각종 법률 서비스를 구축하고자 노력하고 있고 각 분야에서 성과를 내고 있습니다. ")])]), _vm._l(_vm.info, function (item, index) {
    return _c('div', {
      key: index
    }, [index == 0 ? _c('v-divider') : _vm._e(), _c('div', {
      staticClass: "py-30px py-md-60px"
    }, [_c('v-row', {
      class: {
        'flex-md-row-reverse': index % 2 == 0
      }
    }, [_c('v-col', {
      staticClass: "mb-10px mb-md-0",
      attrs: {
        "cols": "auto",
        "md": "auto"
      }
    }, [_c('v-img', {
      attrs: {
        "data-aos": "zoom-out",
        "data-aos-delay": "150",
        "src": '/images/sub/introduction/center/0' + (index + 1) + '.svg',
        "aspect-ratio": 378 / 252,
        "max-width": _vm.$vuetify.breakpoint.mdAndUp ? 378 : 120,
        "contain": ""
      }
    })], 1), _c('v-col', {
      attrs: {
        "cols": "auto",
        "md": ""
      }
    }, [_c('v-sheet', {
      class: {
        'ml-md-auto': index % 2 !== 0
      },
      attrs: {
        "max-width": "670"
      }
    }, [_c('v-card', {
      staticClass: "mb-30px mb-md-60px",
      attrs: {
        "data-aos": "fade-up",
        "rounded": ""
      }
    }, [_c('v-img', {
      staticClass: "w-100",
      attrs: {
        "src": item.image,
        "aspect-ratio": 670 / 446
      }
    })], 1), _c('txt', {
      staticClass: "txt--xl txt--dark line-height-17",
      attrs: {
        "data-aos": "fade-up"
      }
    }, [_vm._v(" " + _vm._s(item.txt) + " ")])], 1)], 1)], 1)], 1), _c('v-divider')], 1);
  })], 2), _c('page-section', {
    staticStyle: {
      "background-image": "url(/images/sub/introduction/center/center-bg2.jpg)"
    },
    attrs: {
      "tit": "태성의 역량 및 노하우",
      "titAdd": "Introduction",
      "dark": ""
    }
  }, [_c('v-row', {
    staticClass: "ma-lg-0 row-cols-lg-5",
    attrs: {
      "justify": "center"
    }
  }, _vm._l(_vm.ability, function (item, index) {
    return _c('v-col', {
      key: index,
      staticClass: "pa-lg-0",
      class: {
        'border-lg-l': index !== 0
      },
      staticStyle: {
        "border-color": "rgba(255, 255, 255, .2) !important"
      },
      attrs: {
        "data-aos": "fade-up",
        "data-aos-delay": _vm.$vuetify.breakpoint.mdAndUp ? index * 50 : 0,
        "cols": "6",
        "md": "4"
      }
    }, [_c('div', {
      staticClass: "w-100 px-16px px-md-20px px-lg-40px d-flex flex-column align-center text-center"
    }, [_c('v-img', {
      attrs: {
        "src": item.icon,
        "width": _vm.$vuetify.breakpoint.mdAndUp ? 80 : 68,
        "aspect-ratio": 1 / 1,
        "contain": ""
      }
    }), _c('tit', {
      staticClass: "tit--sm white--text mt-12px mt-md-24px"
    }, [_vm._v(_vm._s(item.title))])], 1)]);
  }), 1), _c('v-card', {
    staticClass: "mt-30px mt-md-60px",
    attrs: {
      "rounded": "",
      "color": "grey darken-4"
    }
  }, [_c('div', {
    staticClass: "pa-20px pa-md-30px px-lg-40px py-lg-80px d-flex flex-column align-center text-center"
  }, [_c('v-img', {
    attrs: {
      "data-aos": "fade-up",
      "src": "/images/symbol.svg",
      "width": "92",
      "aspect-ratio": 92 / 80
    }
  }), _c('span', {
    staticClass: "line my-12px my-md-24px",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "50"
    }
  }), _c('tit', {
    staticClass: "tit--sm white--text mb-8px mb-md-16px",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "100"
    }
  }, [_vm._v(" 재개발·재건축조합, 입주자대표회의, 입주예정자협의회 등 입주민 분들의 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _c('span', {
    staticClass: "primary--text"
  }, [_vm._v("든든한 지원자이자 최고의 파트너")]), _vm._v("로서 자리매김하고자 최선을 다하겠습니다. ")]), _c('txt', {
    staticClass: "white--text line-height-17",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "150"
    }
  }, [_vm._v(" 대한변호사협회 건설전문변호사 등록 및 건축 분야 최고 수준 자격증인 건축시공기술사, 건축기사, 토목기사를 보유한 윤영환 변호사가 주축이 되어 업무를 수행하며 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 기술자 출신 변호사로 하자진단 단계부터 유기적인 소통을 통해 하자보수 소송에서 최선의 성과를 이끌어 냅니다. ")])], 1)])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }