<template>
    <page-section class="page-section--slide grey lighten-5">
        <tit-wrap-primary class="page-section--slide__title">
            사무실 전경
            <template #TitAdd>Contact Us</template>
        </tit-wrap-primary>
        <div class="slide-contents">
            <!-- <div data-aos="fade-up" cols="12" class="slide-contents__btn-group mb-16px mb-md-0">
                <v-row align="center">
                    <v-col cols="auto">
                        <btn-arrow-primary prev color="grey" class="swiper-btn-prev" />
                    </v-col>
                    <v-col cols="auto">
                        <span class="line"></span>
                    </v-col>
                    <v-col cols="auto">
                        <btn-arrow-primary next color="grey" class="swiper-btn-next" />
                    </v-col>
                </v-row>
            </div>         -->
            <div class="slide-contents__slide w-100">
                <v-row>
                    <v-col data-aos="fade-up" data-aos-delay="200" cols="12" md="8" offset-md="4" class="slide-contents__right">  
                        <!-- <swiper ref="swiperslide" :options="swiperOptionslide">
                            <slot />
                        </swiper> -->
                        <slot />
                    </v-col>
                    <v-col data-aos="fade-up" data-aos-delay="300" cols="12" md="10" lg="5" class="slide-contents__left">            
                        <div class="slide-contents__left__inner">
                            <v-card rounded dark color="primary" class="slide-contents__left__card">
                                <div class="pa-20px pa-md-30px pa-lg-60px">
                                    <tit class="white--text">
                                        법무법인 태성의 변호사는 <br class="d-none d-xl-block">
                                        여러분 곁에 있습니다.
                                    </tit>
                                </div>
                            </v-card>
                        </div>
                    </v-col>
                </v-row>   
            </div>
        </div>
    </page-section>
</template>

<script>
// import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import PageSection from "@/sets/styles/pages/page-section.vue";
import TitWrapPrimary from "@/components/publish/parents/typography/tit-wrap-primary.vue";
import BtnArrowPrimary from "@/components/publish/parents/buttons/btn-arrow-primary.vue";

import Tit from "@/components/publish/styles/typography/tit.vue";
import Txt from "@/components/publish/styles/typography/txt.vue";
export default {
    props: {
    },
    components: {
        // Swiper,
        // SwiperSlide,
        PageSection,
        TitWrapPrimary,
        BtnArrowPrimary ,

        Tit,
        Txt,
    },
    data() {
        return {
            // swiperOptionslide: {
            //     loop: true,
            //     slidesPerView: 1,
            //     spaceBetween: 12,
            //     speed: 500,
            //     lazy: {
            //       loadPrevNext: true,
            //       loadPrevNextAmount: 10
            //     },
            //     loopedSlides: 10,
            //     autoplay: {
            //         delay: 5000,
            //         disableOnInteraction: false,
            //     },
            //     navigation: {
            //         nextEl: ".slide-contents__btn-group .swiper-btn-next",
            //         prevEl: ".slide-contents__btn-group .swiper-btn-prev",
            //     },
            //     breakpoints: {
            //         1544:{
            //             spaceBetween: 60,
            //         },
            //     },
            // },
        };
    },
}
</script>

<style lang="scss" scoped>
.page-section--slide{
    position: relative;
}
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
}
@media (min-width:1200px){
}
@media (min-width:1440px){
    .page-section--slide{
        &__title{
            position: absolute;
            left: 12px;
            top: 12px;
            z-index: 2;
        }
    }
}
</style>