<template>
    <client-page>
        <template #subHead>
            <sub-visual sh="업무분야" tabActive="건설 엔지니어링" bg="/images/sub/visual/sv-business.jpg" />
        </template>
        <page-section tit="건설 엔지니어링 서비스" titAdd="Business" class="text-bg" style="background-image: url(/images/sub/business/business-bg.svg);">
            <div class="card-rows">
                <card-row image="/images/sub/business/engineering/engineering-img.jpg">
                    <p>
                        법무법인 태성은 윤영환 대표변호사가 건축시공기술사 자격을 보유하고 있고, 법무법인 태성 이름으로 기술사사무소로 등록되어 있습니다. 외부 기술사사무소를 협력업체로 두고 업무를 진행하는 다른 법무법인과
                        달리, 법무법인 태성은 법무법인이자 기술사사무소이고 외부
                        기술사사무소와 협업도 하고 있습니다.
                    </p>
                </card-row>
                <card-row image="/images/sub/business/engineering/engineering-img2.jpg">
                    <p>
                        태성은 아파트 재개발·재건축조합, 입주예정자협의회, 입주자대표회의 등 아파트 입주민들을 위한 법률 기술 분야의 종합적인 서비스를 제공하고 있고, 기술사사무소로서 아파트 안전진단, 감리, 품질점검 등 전문기술분야로도 업무범위를 확대해 나갈 예정입니다.
                    </p>
                    <p>
                        태성은 아파트 생애 주기 전반에 걸쳐 입주민에게 필요한 법률분야
                        기술분야 모든 서비스를 구축하여, 아파트 입주민들에게 최고의
                        파트너가 되겠습니다.
                    </p>
                </card-row>
            </div>
        </page-section>
    </client-page>
</template>

<script>

import ClientPage from "@/sets/styles/apps/client-page.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import PageSection from "@/sets/styles/pages/page-section.vue";
import CardRow from "@/sets/styles/card-row.vue";

import Tit from "@/components/publish/styles/typography/tit.vue";
import Txt from "@/components/publish/styles/typography/txt.vue";

export default {
    props: {},
    components: {
        ClientPage,
        SubVisual,
        PageSection,
        CardRow,

        Tit,
        Txt,
    },
    data() {
        return {
        }
    },
};
</script>

<style lang="scss" scoped>
</style>
