<template>
    <client-page>
        <template #subHead>
            <sub-visual sh="오시는 길" tabActive="광주분사무소" bg="/images/sub/visual/sv-contact.jpg" />
        </template>
        <contact-info 
            title="광주분사무소"
            iframe="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3262.2661579744517!2d126.93071957628064!3d35.14998235897328!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x35718cbde2cd95a7%3A0x1d98c0ba364f6baf!2z6rSR7KO86rSR7Jet7IucIOuPmeq1rCDsp4DsgrDroZwgNzA!5e0!3m2!1sko!2skr!4v1692692301259!5m2!1sko!2skr"
            address="광주 동구 지산로 70, 동산빌딩 2층"
            addressLink="https://goo.gl/maps/UoYnT7Ne8TuZmkVR8"
            tel="062-229-1727"
            fax="062-229-1728" />
        <contact-slide>
            <v-img src="/images/sub/contact/gwangju/gwangju-img.jpg" max-width="952" :aspect-ratio="952 / 634" class="w-100" />
        </contact-slide>
    </client-page>
</template>

<script>

import ClientPage from "@/sets/styles/apps/client-page.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import PageSection from "@/sets/styles/pages/page-section.vue";
import ContactInfo from "@/sets/styles/contact-info.vue";
import ContactSlide from "@/sets/styles/contact-slide.vue";

import Tit from "@/components/publish/styles/typography/tit.vue";
import Txt from "@/components/publish/styles/typography/txt.vue";

export default {
    props: {},
    components: {
        ClientPage,
        SubVisual,
        PageSection,
        ContactInfo,
        ContactSlide,

        Tit,
        Txt,
    },
    data() {
        return {
        }
    },
};
</script>

<style lang="scss" scoped>
</style>
