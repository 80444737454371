<template>
    <client-page>
        <template #subHead>
            <sub-visual sh="Lawfirm taesung & P.E" tabActive="윤영환 대표 소개" bg="/images/sub/visual/sv-introduction.jpg" />
        </template>
        <page-section tit="윤영환 대표 소개" titAdd="Introduction" class="lawyer-view__head" titClass="pb-0">
            <template #pageSectionImmersive>
                <v-img src="/images/sub/introduction/lawyer/lawyer-bg.svg" max-width="1840" :aspect-ratio="1840 / 266" class="text-bg w-100" />
            </template>
        </page-section>
        <page-section class="lawyer-view__body grey darken-3">
            <v-row>
                <v-col cols="12" sm="7">
                    <tit data-aos="fade-up" class="tit--lg white--text">
                        어떠한 난제에 봉착하더라도 <br>
                        <span class="primary--text">심도 있는 실무적 관점으로 다가가겠습니다.</span>
                    </tit>
                    <span data-aos="fade-up" data-aos-delay="100" class="line my-12px my-md-24px"></span>
                    <txt data-aos="fade-up" data-aos-delay="200" class="white--text line-height-17">
                        <p>
                            윤영환 변호사는 건설 전공자 출신으로 건축시공기술사와 토목기사를 취득하여 활동하고 있는 실무가 출신의
                            대표변호사로서, 각 분야에 심도 있는 이해를 추구하고 의뢰인과 가깝게 호흡하는 현장 밀착형 로펌을 추구합니다.
                        </p>
                        <p>
                            기존에 한계라 여겼던 부분들을 실무적 관점으로 풀어가는 변호사인 만큼, 풀리지 않는 난제에 봉착하였다면, <br class="d-none d-xl-block">
                            언제든지 윤영환 대표변호사를 찾아 주시기 바랍니다.
                        </p>
                    </txt>
                </v-col>
                <v-col cols="8" sm="5" offset="4" offset-sm="7" offset-md="0">
                    <div class="lawyer-img-wrap">
                        <v-img data-aos="fade-left" src="/images/lawyer/view/윤영환.png" max-width="586" :aspect-ratio="586 / 900" class="lawyer-img w-100"/>
                    </div>
                </v-col>
            </v-row>
        </page-section>
        <page-section class="lawyer-view__foot page-section--last page-section--xs">
            <info-row title="소개글" topLine>
                <txt class="txt--dark line-height-17">
                    <p>
                        건축물 하자관리 분야에서는, 스프링클러 전면교체, 방화문 전면교체 판결 등은 물론
                        찾아보기 어려운 전선
                        전면교체, 승강기 전면교체 등의 우수한 성과를 이뤄 오고 있습니다.
                    </p>
                    <p>
                        또한 입주예정자협의회 지원 분야에서는,
                        기존의 명목뿐이었던 로펌의 역할을 파트너 지위로 끌어올렸다는 평가를 얻고 있으며 정비사업 분야에서는
                        시공사와 대등한 입장에서의
                        계약조율, 확실한 정산 소송 등의 성과를 얻어오고 있습니다.
                    </p>
                    <p>
                        건설 실무가 출신의 변호사답게, 변호사, 기술자, 스태프들의 유기적인 소통과 조화를
                        이끌어 내어 단체소송,
                        집단소송에서 압도적인 성과를 자랑하고 있습니다.
                    </p>
                </txt>
            </info-row>
            <info-row v-for="(item, index) in info" :key="index" :title="item.title">
                <info-detail v-for="(detail, index) in item.infoDetail" :key="index" :class="index !== 0 ? 'mt-10px mt-md-16px' : ''">
                    {{detail}}
                </info-detail>
            </info-row>
        </page-section>
    </client-page>
</template>

<script>

import ClientPage from "@/sets/styles/apps/client-page.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import PageSection from "@/sets/styles/pages/page-section.vue";
import InfoRow from "@/sets/styles/info-row.vue";
import InfoDetail from "@/sets/styles/info-detail.vue";

import Tit from "@/components/publish/styles/typography/tit.vue";
import Txt from "@/components/publish/styles/typography/txt.vue";

export default {
    props: {},
    components: {
        ClientPage,
        SubVisual,
        PageSection,
        InfoRow,
        InfoDetail,

        Tit,
        Txt,
    },
    data() {
        return {          
            info: [
                {
                    title : "약력",
                    infoDetail : [
                        "건축기사, 토목기사",
                        "아파트 하자보수소송 300여건 담당",
                        "입주예정자협의회 법률지원 변호사",
                        "건설전문지 건설이코노미뉴스 칼럼니스트",
                        "고려대학교 졸업 (건설 전공)",
                        "대한변호사협회 등록 건설전문변호사",
                        "대한변호사협회 재개발·재건축전문변호사",
                        "입주예정자협의회 법률지원 변호사",
                        "하자 분야 국회정책토론위원",
                        "MBC 등 언론사 법률자문 제공",
                    ],
                },
            ],
        }
    },
};
</script>

<style lang="scss" scoped>
</style>
